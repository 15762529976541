const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.wecomplai.forwoodsafety.com',
    APP_CLIENT_ID: '174j4k6eu2g81ben2s0pg2l5ue',
    USER_TOKEN_URL: 'https://xhhzmu46k7.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://xj5eigmv7c.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.dev.wecomplai.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.dev.wecomplai.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.dev.wecomplai.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.wecomplai.forwoodsafety.com',
    WEBSOCKET: 'wss://s9hvljacde.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;